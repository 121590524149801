import React, {createContext, useContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Session from 'supertokens-auth-react/recipe/session';
import {redirectToAuth} from 'supertokens-auth-react';
import {LayoutSplashScreen} from "../../../_metronic/layout/core";
import axios from "axios";

import TenantService from '../tenant/services/TenantService';


type Tier = {
    id: string;
    name: string;
    percent: number;
    minimumFee: number;
    default: boolean;
}

type User = {
    id: string
    fullName: string;
    email: string;
    userType: string;
    status: string;
    tier: Tier;
    organizationId: string;
    payoutsEnabled: boolean;
    detailsSubmitted: boolean;
} | null;

type UserContextType = {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

const AuthWrapperComponent = () => {
    const [user, setUser] = useState<User>(null);
    const [authStatus, setAuthStatus] = useState<'loading' | 'authenticated' | 'unauthenticated'>('loading');
    const location = useLocation();
    const navigate = useNavigate();

    async function fetchUserDetails() {
        const response = await axios.get('/api/v1/user/me')

        setUser(response.data)
    }

    async function checkAuthStatus() {
        if (window.location.pathname.startsWith("/auth")) {
            // If already on an auth page, avoid the redirection
            setAuthStatus('unauthenticated');
            return;
        }

        if (!await Session.doesSessionExist()) {
            await redirectToAuth();
            setAuthStatus('unauthenticated');
            return;
        }

        setAuthStatus('authenticated');

        if (localStorage.getItem('tenant') == null) {
            await TenantService.fetchTenantData();
        }

        await fetchUserDetails();

        // If users loads the index page and is authenticated, navigate to /dashboard
        if (window.location.pathname === '/') {
            navigate('/dashboard');
        }
    }

    useEffect(() => {
        if (authStatus === 'loading') {
            checkAuthStatus();
        }
    }, [location.pathname]);

    if (authStatus === 'loading') {
        return <LayoutSplashScreen/>;
    }

    if (authStatus === 'unauthenticated') {
        return null;  // Or redirect, or show some other UI
    }

    return (
        <UserContext.Provider value={{user, setUser}}>
            <Outlet/>
        </UserContext.Provider>
    );
}

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export const AuthWrapper = AuthWrapperComponent;