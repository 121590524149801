import axios from "axios";


const DashboardService = {
    fetchOverview: async (): Promise<Overview> => {
        const response = await axios.get("/api/v1/dash/consignor/overview")

        return response.data;
    },
    fetchTrendingProducts: async (): Promise<TrendingProducts[]> => {
        const response = await axios.get("/api/v1/dash/trending")

        return response.data.trending;
    }
}

export default DashboardService;


export interface Overview {
    overview: {
        totalItems: number;
        awaitingPayoutAmount: number;
    };
    report: {
        totalItemsSold: number;
        totalRevenue: number;
        averageSalePrice: number;
    };
}


export interface TrendingProducts {
    id: string,
    title: string,
    imageUrl: string,
    totalSold: number,
    averageSubtotal: number,
}