import {Tenant} from "../../auth/supertokens";
import axios from "axios";

const TenantService = {
    getTenantFromLocalStorage: (): Tenant | undefined => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];

        const tenantDataStr = localStorage.getItem('tenant');

        if (tenantDataStr) {
            const tenantData = JSON.parse(tenantDataStr) as Tenant

            TenantService.updateLogos(tenantData);

            // If parsed correct and the subdomain matches, return the tenantId from localStorage
            if (tenantData.subdomain && tenantData.subdomain && tenantData.subdomain === subdomain)
                return tenantData;
        }

        return undefined;
    },
    fetchTenantData: async () : Promise<Tenant> => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];

        // Get the tenantId from the endpoint
        const response = await axios.get(`/api/v1/organization/subdomain/${subdomain}`);

        const tenantData = {
            subdomain,
            ...response.data
        } as Tenant

        localStorage.setItem('tenant', JSON.stringify(tenantData));

        TenantService.updateLogos(tenantData);

        return tenantData;
    },
    updateLogos: (tenant: Tenant) => {
        const favicon = document.querySelector('head > link:nth-child(7)') as HTMLLinkElement | null;

        if(favicon) {
            favicon.href = tenant?.organizationSetting.faviconUrl;
        }

        const splashLogo = document.querySelector('#splash-screen > img.dark-logo') as HTMLImageElement | null;

        if(splashLogo) {
            splashLogo.src = tenant.organizationSetting.logoUrl;
        }

        const splashLogoLight = document.querySelector('#splash-screen > img.light-logo') as HTMLImageElement | null;

        if(splashLogoLight) {
            splashLogoLight.src = tenant.organizationSetting.logoUrl;
        }
    }
}

export default TenantService;