import { useIntl } from "react-intl"
import { PageTitle } from "../../../_metronic/layout/core"
import { Helmet } from "react-helmet-async"
import { PaginatedTable, TableHeader } from "components"
import { Link } from "react-router-dom"
import {Card} from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";

const Page = () => {
    const headers: TableHeader[] = [
        {
            key: "id",
            label: "Reference",
            format: (cell) => {
                return (
                    <Link className="text-grey" to={`/payouts/paid/${cell.row.id}`}>
                        {cell.row.payoutRef}
                    </Link>
                )
            }
        },
        {
            key: "createdAt",
            label: "Created At",
            format: (cell) => TableUtilities.formatDate(cell.item)
        },
        {key: "quantity", label: "Item Count"},
        {
            key: "payoutAmount",
            label: "Payout Amount",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item),
        }
    ]

    return (
        <>
        <Card>
            <Card.Body className="pt-5">
                <PaginatedTable
                    key={null}
                    headers={headers}
                    dataProperty="payouts"
                    method="get"
                    dataUrl="/api/v1/payout/paid/all"
                    className={""}
                    filterLocally={true}
                />
            </Card.Body>
        </Card>
        </>
    )
}


const PayoutPaidWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "MENU.PAYOUT_PAID"})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: "MENU.PAYOUT_PAID"})}</title>
            </Helmet>
            <Page/>
        </>
    )
}
export default PayoutPaidWrapper;