import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import DashboardWrapper from "../modules/dashboard/dashboard";
import { DashboardPage } from '../modules/dashboard/pages/dashboard'
import OrdersPage from "../modules/orders/pages/orders";
import {ViewOrderPage} from "../modules/orders/pages/view";
import ViewInventoryItemPage from "../modules/inventory/pages/view";
import InventoryPage from "../modules/inventory/pages/inventory";
import {SettingsPage} from "../modules/settings/settings";
import {CatalogPage} from "../modules/catalog/pages/catalog";
import {ProductPage} from "../modules/catalog/pages/product";
import PayoutAwaiting from "../modules/payouts/PayoutAwaiting";
import PayoutPaid from "../modules/payouts/PayoutPaid";
import ViewPaidPayoutPage from "../modules/payouts/ViewPaid";
import ViewAwaitingPayoutPage from "../modules/payouts/ViewPayoutAwaiting";
import OnboardWrapper from "../modules/catalog/pages/onboard";
import {SessionAuth} from 'supertokens-auth-react/recipe/session';

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Pages */}
                <Route path='dashboard' element={<SessionAuth> <DashboardPage/> </SessionAuth>}/>
                <Route path='inventory' element={<SessionAuth> <InventoryPage/> </SessionAuth>}/>
                <Route path='inventory/items/:id' element={<SessionAuth> <ViewInventoryItemPage/></SessionAuth>}/>
                <Route path='payouts' element={<SessionAuth> <OrdersPage/></SessionAuth>}/>
                <Route path='/payouts/awaiting' element={<SessionAuth> <PayoutAwaiting/></SessionAuth>}/>
                <Route path='/payouts/paid' element={<SessionAuth> <PayoutPaid/></SessionAuth>}/>
                <Route path='/payouts/paid/:id' element={<SessionAuth> <ViewPaidPayoutPage/></SessionAuth>}/>
                <Route path='/payouts/awaiting/current' element={<SessionAuth> <ViewAwaitingPayoutPage/></SessionAuth>}/>
                <Route path='orders/:id' element={<SessionAuth> <ViewOrderPage/></SessionAuth>}/>
                <Route path='catalog' element={<SessionAuth> <CatalogPage/></SessionAuth>}/>
                <Route path='catalog/:id' element={<SessionAuth> <ProductPage/> </SessionAuth>}/>
                <Route path='catalog/:id/create' element={<SessionAuth> <OnboardWrapper/> </SessionAuth>}/>
                <Route path='settings' element={<SessionAuth> <SettingsPage/> </SessionAuth>}/>
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/dashboard'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
