/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            <AsideMenuItem to='/dashboard' title='Dashboard' icon='element-11'/>
            <AsideMenuItem to='/inventory' title='Inventory' icon='price-tag'/>
            <AsideMenuItemWithSub to='/payouts' title='Payouts' icon='dollar'>
                <AsideMenuItem to='/payouts/awaiting' title='Awaiting Payment' hasBullet={true}/>
                <AsideMenuItem to='/payouts/paid' title='Paid' hasBullet={true}/>
            </AsideMenuItemWithSub>
            <AsideMenuItem to='/catalog' title='Catalog' icon='book'/>
            <AsideMenuItem to='/settings' title='Settings' icon='setting-2'/>
        </>
    )
}
