import axios from "axios";
import {EventType} from "@testing-library/react";

const InventoryService = {
    fetchInventory: async (): Promise<InventoryItem[]> => {
        const response = await axios.get('/api/v1/item/consignor/search')

        return response.data.employees
    },
    fetchLocations: async (): Promise<Location[]> => {
        const response = await axios.get('/api/v1/location/all')

        return response.data.locations
    },
    fetchItem: async (itemId: string): Promise<InventoryItem> => {
        const response = await axios.get(`/api/v1/item/view/${itemId}`)

        return response.data;
    },
    fetchItemAvailability: async (itemId: string) : Promise<VariantAvailability[]> => {
        const response = await axios.get(`api/v1/item/view/product/${itemId}/availability`)

        return response.data.sizes;
    },
    fetchItemSizeAvailability: async (productId: string, size: string, shape: string) : Promise<ItemPrice> => {
        const response = await axios.post('api/v1/item/view/product/size/availability', {
            'product_id': productId,
            'size': size,
            'shape': shape
        })

        return response.data;
    },
}

export default InventoryService;

export enum InventoryItemStatus {
    All = 'all',
    Awaiting = 'awaiting_item',
    Active = 'active',
    InQueue = 'in_queue',
    WithdrawalRequested = 'withdrawal_requested',
    Sold = 'sold',
    Withdrawn = 'withdrawn',
}

export interface InventoryItem {
    id: string,
    createdAt: string,
    size: string,
    price: number,
    payoutAmount: number,
    shape: string,
    status: InventoryItemStatus,
    storeOwned: boolean,
    product: Product,
    location: Location,
    user: User
    event: InventoryItemEvent[]
}

interface InventoryItemEvent {
    id:string;
    createdAt: string;
    title: string;
    description: string;
    eventType: ProductEventType;
    user?: ProductUserSimple
}

interface ProductUserSimple {
    id: string
    fullName: string
    userType: string
}


interface Product {
    id: string,
    createdAt: string,
    title: string,
    brand: string,
    sku: string,
    colorway: string,
    category: string,
    imageUrl: string,
}

export interface Location {
    id: string,
    name: string,
    addressOne: string,
    addressTwo: string,
    zipCode: string,
    city: string,
    state: string,
    country: string,
    shopifyLocationId: string
}

interface User {
    id: string,
    firstName: string,
    lastName: string,
    userType: string,
    fullName: string,
}

export interface ItemPrice {
    price: number | null
}

export enum ProductEventType {
    ITEM_WITHDRAWN = "ITEM_WITHDRAWN",
    CATEGORY_UPDATED = "CATEGORY_UPDATED",
    ITEM_WITHDRAWAL_CREATED = "ITEM_WITHDRAWAL_CREATED",
    ITEM_ACCEPTED = "ITEM_ACCEPTED",
    LOCATION_UPDATED = "LOCATION_UPDATED",
    TIER_UPDATED = "TIER_UPDATED",
    PRODUCT_CREATED = "PRODUCT_CREATED",
    ITEM_PRICE_UPDATE = "ITEM_PRICE_UPDATE",
    ITEM_ONBOARDED = "ITEM_ONBOARDED",
    TIER_CREATED = "TIER_CREATED",
}

export interface VariantAvailability {
    size: string
    price?: number
}