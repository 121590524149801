import { useIntl } from "react-intl"
import { PageTitle } from "../../../_metronic/layout/core"
import { Helmet } from "react-helmet-async"
import { PaginatedTable, TableHeader } from "components"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import TableUtilities from "components/dist/tables/table-utilities";


const Page = () => {
    const headers: TableHeader[] = [
        {
            key: "payoutCount",
            label: "Item Count",
            format: (cell) => {
                return (
                    <Link className="text-white" to="/payouts/awaiting/current">
                        {cell.row.payoutCount}
                    </Link>
                )
            }
        },
        {
            key: "subtotal",
            label: "Subtotal",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item)
        },
        {
            key: "payoutAmountSubtotal",
            label: "Payout Amount",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item)
        }
    ]

    return (
        <>
            <Card>
                <Card.Body className="pt-5">
                    <PaginatedTable
                        key={null}
                        headers={headers}
                        dataProperty="payouts"
                        method="get"
                        dataUrl="/api/v1/payout/awaiting/all"
                        className={""}
                        filterLocally={true}
                    />
                </Card.Body>
            </Card>
        </>
    )
}


const PayoutAwaitingWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "MENU.PAYOUT_AWAITING"})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: "MENU.PAYOUT_AWAITING"})}</title>
            </Helmet>
            <Page/>
        </>
    )
}
export default PayoutAwaitingWrapper;