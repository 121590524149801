import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {CatalogSearch} from "components";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import TenantService from "../../tenant/services/TenantService";


const CatalogPageContent = () => {
    return (
        <>
            <Row className='mh-100 justify-content-center'>
                <Col md={'8'} xs={'12'}>
                    <CatalogSearch/>
                </Col>
            </Row>
        </>
    )
}

const CatalogPageWrapper = () => {
    const intl = useIntl()

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CATALOG'})}</PageTitle>
            <CatalogPageContent/>
            <Helmet>
                <title>
                    {intl.formatMessage({id: 'MENU.CATALOG'})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}

export const CatalogPage = CatalogPageWrapper