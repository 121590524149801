import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import {stat} from "fs";

interface Props {
    onFilterChange: (filters: Record<string, string>) => void,
}

const statuses = {
    'all': 'All',
    'awaiting_payment': 'Awaiting Payment',
    'paid': 'Paid'
}

export const OrdersFilterDropdown = ({onFilterChange}: Props) => {
    const defaultStatus = 'all';

    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>(defaultStatus)

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Toggle the dropdown's open/closed state
        setIsOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value)
    }

    const resetFilters = () => {
        setSelectedStatus(defaultStatus)
    }

    const onConfirmFilters = (close: boolean) => {
        onFilterChange({
            ...selectedStatus && {'status': selectedStatus},
        })

        if (close)
            handleClose();
    }

    return (
        <>
            <Dropdown show={isOpen} onToggle={handleOpen} ref={dropdownRef}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" onClick={handleToggleClick}>
                    <i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span
                        className="path2"></span></i>
                    Filter
                </Dropdown.Toggle>

                <Dropdown.Menu className={'w-400px px-2 py-4'}>
                    <Dropdown.Header>
                        <span className='text-white fw-bold h4'>Filter Options</span>
                    </Dropdown.Header>

                    <Dropdown.Divider/>

                    <Form className=''>

                        <Form.Group className="mb-3 p-4" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                className='form-select form-select-solid'>
                                {Object.entries(statuses).map(([key, value]) => {
                                    return (
                                        <option value={key}>
                                            {value}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Form>

                    <div className='d-flex justify-content-end pe-4'>
                        <Button variant={'secondary'} className='me-3' onClick={resetFilters}>Reset</Button>
                        <Button variant={'primary'} onClick={() => onConfirmFilters(true)}>Apply</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
