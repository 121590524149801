import axios from "axios";

const PayoutService = {
    fetchBatchPayout: async (batchId: string) : Promise<PayoutBatchRoot> => {
        const response = await axios.get(`/api/v1/payout/view/batch/${batchId}`)
        return response.data
    },
    fetchCurrentPayout: async () : Promise<PayoutRoot> => {
        const response = await axios.get("/api/v1/payout/awaiting/current")
        return response.data;
    }
}


export interface PayoutRoot {
    details: PendingPayoutBatchDetails;
    payouts: Payout[];
}


export interface PayoutBatchRoot {
    details: PayoutBatchDetails;
    batchPayout: PayoutBatch;   
}

interface PayoutBatchDetails {
    checkCount: number;
    email: string;
    payoutCount: number;
    payoutAmountSubtotal: number;
}

interface PendingPayoutBatchDetails {
    payoutCount: number;
    subtotal: number;
    payoutAmountSubtotal: number;
}

interface PayoutBatch {
    id: string;
    createdAt: string;
    payoutRef: string;
    quantity: number;
    payout: Payout[];
}

interface Payout {
    id: string;
    createdAt: string;
    transferSent: boolean;
    orderItem: OrderItem;
}

interface OrderItem {
    id: string;
    createdAt: string;
    subtotal: number;
    payoutAmount: number;
    status: string;
    item: ProductItem;
}

interface ProductItem {
    id: string;
    createdAt: string;
    storeOwned: boolean;
    size: string;
    price: number;
    shape: string;
    status: string;
    product: ProductDetails;
}

interface ProductDetails {
    id: string;
    createdAt: string;
    title: string;
    primaryTitle: string;
    secondaryTitle: string;
    brand: string;
    sku: string;
    colorway: string;
    gender: string;
    releaseDate: string;
    imageUrl: string;
}


export default PayoutService;