import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import {Card, Col, Row} from "react-bootstrap";

import { PageTitle } from "../../../../_metronic/layout/core";
import DashboardService, { Overview } from "../services/dashboard.service";
import TenantService from "../../tenant/services/TenantService";
import { StatisticsWidgetBasic } from "../../../../_metronic/partials/widgets";
import TrendingProductsComponent from "../components/TrendingProducts";
import TableUtilities from "components/dist/tables/table-utilities";


const DashboardPageContent = () => {
    const [overview, setOverview] = useState<Overview | undefined>(undefined);


    const fetchOverview = async () => {
        setOverview(await DashboardService.fetchOverview())
    }

    const fetchData = async () => {
        await fetchOverview();
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })()
    }, [])

    return (
        <>
            <Row className="g-5 g-xl-8">
                <Col xl={4} className="d-flex flex-column h-100">
                    <StatisticsWidgetBasic
                        className="card-xl-stretch mb-5 mb-xl-8 bg-primary"
                        svgIcon="abstract-29"
                        color=""
                        iconColor="black"
                        title={overview?.overview?.totalItems.toString() ?? "0"}
                        titleColor="white"
                        description="Total Items"
                        descriptionColor="white"
                        target="/inventory"
                    />

                    <StatisticsWidgetBasic
                        className="card-xl-stretch mb-5 mb-xl-8"
                        svgIcon="dollar"
                        color=""
                        iconColor=""
                        title={TableUtilities.formatCurrencyCents(overview?.overview?.awaitingPayoutAmount ?? 0)}
                        titleColor="white"
                        description="Awaiting Payment"
                        descriptionColor="white"
                        target="/payouts/awaiting"
                    />

                    <Card>
                        <Card.Header className="d-block pt-6">
                            <h5>Monthly Report</h5>
                        </Card.Header>

                        <Card.Body>
                            <Row className="row">
                                <Col>
                                    <h3 className="text-gray-500 fw-bold fs-7">Revenue</h3>
                                    <span className="fs-5 fw-bold">
                                        {TableUtilities.formatCurrencyCents(overview?.report?.totalRevenue ?? 0)}
                                    </span>
                                </Col>

                                <Col>
                                    <h3 className="text-gray-500 fw-bold fs-7">Items Sold</h3>
                                    <span className="fs-5 fw-bold">
                                        {overview?.report?.totalItemsSold ?? 0}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-between mt-10">
                                <Col>
                                    <h3 className="text-gray-500 fw-bold fs-7">Average Sale Price</h3>
                                    <span className="fs-5 fw-bold">
                                        {TableUtilities.formatCurrencyCents(overview?.report?.averageSalePrice ?? 0)}
                                    </span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={8}>
                    <TrendingProductsComponent />
                </Col>
            </Row>
        </>
    )
}


const DashboardPage = () => {
    const intl = useIntl();

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
            <DashboardPageContent />

            <Helmet>
                <title>
                    {intl.formatMessage({id: "MENU.DASHBOARD"})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}

export { DashboardPage };