import {useIntl} from 'react-intl'
import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {PaginatedTable, TableHeader} from "components";
import {Link} from "react-router-dom";
import TableUtilities from "components/dist/tables/table-utilities";
import {Card} from "react-bootstrap";
import InventoryTable from "../components/InventoryTable";
import {Helmet} from "react-helmet-async";
import TenantService from "../../tenant/services/TenantService";

const InventoryPageContent = () => {

    const [tableFilters, setTableFilters] = useState<Record<string, string>>({});

    const headers: TableHeader[] = [
        {
            key: 'orderNumber', label: 'Order Id', format: (cell) => {
                return (
                    <Link className='text-grey' to={`/inventory/items/${cell.item}`}>{cell.item}</Link>
                )
            }
        },
        {key: 'createdAt', label: 'Sale Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'source', label: 'Sale Source', cellClassName: 'text-capitalize'},
        {key: 'quantity', label: 'Quantity'},
        {key: 'subtotal', label: 'Subtotal', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'profitTotal', label: 'Profit', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
    ]

    const onFilterChange = (filters: Record<string, string>) => {
        setTableFilters(filters);
    }

    const tableActions = () => (
        <>
            <div className='d-flex'>
                {/*<OrdersFilterDropdown onFilterChange={onFilterChange}/>*/}
            </div>
        </>
    )

    return (
        <>
            <Card>
                <Card.Body className='pt-10'>
                    <InventoryTable />
                </Card.Body>
            </Card>
        </>
    )
}

const InventoryPage = () => {
    const intl = useIntl()

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INVENTORY'})}</PageTitle>
            <InventoryPageContent/>

            <Helmet>
                <title>
                    {intl.formatMessage({id: 'MENU.INVENTORY'})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}

export default InventoryPage