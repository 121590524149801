import {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import DashboardService, {TrendingProducts} from "../services/dashboard.service";
import TableUtilities from "components/dist/tables/table-utilities";


const TrendingProductsComponent = (): JSX.Element => {
    const [trendingProducts, setTrendingProducts] = useState<TrendingProducts[]>([]);

    const loadData = async () => {
        setTrendingProducts(await DashboardService.fetchTrendingProducts())
    }

    useEffect(() => {
        (async () => {
            await loadData()
        })()
    }, [])

    return (
        <div className="card card-xl-stretch mb-xl-8">
            {/* begin::Header */}
            
            <div className="card-header border-1 d-flex justify-content-between align-items-center">
                <h5 className="card-label mb-4">
                    Trending Products
                </h5>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body py-3">
                {/* begin::Table Container */}
                <div className="table-responsive">
                    {/* begin::Table */}
                    <table className="table align-middle">
                        {/* begin::Table head */}
                        <thead>
                        <tr className="text-start text-muted fw-bolder fs-8 text-uppercase gs-0">
                            <th className="p-0" style={{ width: "55px" }}></th>
                            <th style={{ maxWidth: "270px", whiteSpace: "normal", wordWrap: "break-word" }}>Product</th>
                            <th>Sales</th>
                            <th>Avg Sold Price</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        {trendingProducts && trendingProducts.map((product, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>
                                        <Link to={`/catalog/${product.id}`}>
                                            <div style={{
                                                width: "55px",
                                                height: "55px",
                                                backgroundColor: "white",
                                                borderRadius: "0.5em",
                                                backgroundImage: `url(${product.imageUrl})`,
                                                backgroundSize: "80%",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center center",
                                            }} />
                                        </Link>
                                    </td>
                                    <td style={{ maxWidth: "270px", whiteSpace: "normal", wordWrap: "break-word" }}>
                                        <div className="d-flex justify-content-start flex-column">
                                            <a href={`/catalog/${product.id}`} className="text-white fw-bold">
                                                {product.title}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text-gray-600 fw-bold d-block">
                                            {product.totalSold}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-gray-600 fw-bold d-block">
                                            {TableUtilities.formatCurrencyCents(product.averageSubtotal)}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TrendingProductsComponent;