import {Button, Card, Col, Nav, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import TableUtilities from 'components/dist/tables/table-utilities'
import {CatalogItemView, CatalogService, PaginatedTable, Pagination, TableHeader} from 'components'
import OnboardItemsModal from '../components/OnboardItemsModal'
import {Helmet} from 'react-helmet-async'
import TenantService from '../../tenant/services/TenantService'

const tabs = {
  inventory: 'inventory',
  orders: 'orders',
} as const

type Tab = typeof tabs[keyof typeof tabs]

const ProductPageContent = () => {
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs.inventory)
  const [itemDetails, setItemDetails] = useState<CatalogItemView | null>(null)
  const [numOrders, setNumOrders] = useState<number>(0)
  const [numInventory, setNumInventory] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const {id} = useParams()

  useEffect(() => {
    ;(async () => {
      setItemDetails(await CatalogService.getItem(id!))
    })()
  }, [])

  const inventoryHeaders: TableHeader[] = [
    {
      key: 'size',
      label: 'Size',
      rowClassName: 'min-w-100px',
      format: (cell) => (
        <Link to={`/inventory/items/${cell.row.id}`} className='text-grey'>
          {cell.item}
        </Link>
      ),
    },
    {
      key: 'shape',
      label: 'Condition',
      format: (cell) => <span className='text-capitalize'>{cell.item}</span>,
    },
    {key: 'location.name', label: 'Location'},
    {
      key: 'price',
      label: 'price',
      format: (cell) => TableUtilities.formatCurrency(cell.item / 100),
    },
    {
      key: 'payoutAmount',
      label: 'Payout Amount',
      format: (cell) => TableUtilities.formatCurrencyCents(cell.item),
    },
    {key: 'createdAt', label: 'Created At', format: (cell) => TableUtilities.formatDate(cell.item)},
    {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
  ]

  const ordersHeaders: TableHeader[] = [
    {
      key: 'id',
      label: 'Order Id',
      format: (cell) => <span className='text-grey'>{cell.item}</span>,
    },
    {key: 'item.size', rowClassName: 'min-w-100px', label: 'Size'},
    {key: 'item.shape', label: 'Condition'},
    {key: 'createdAt', label: 'Sale Date', format: (cell) => TableUtilities.formatDate(cell.item)},
    {
      key: 'item.storeOwned',
      label: 'Ownership Type',
      format: (cell) => <span>{cell.row.storeOwned ? 'Store' : cell.row.item.user.fullName}</span>,
    },
    {
      key: 'item.price',
      label: 'Price',
      format: (cell) => TableUtilities.formatCurrency(cell.item / 100),
    },
    {
      key: 'profitTotal',
      label: 'Profit',
      format: (cell) => TableUtilities.formatCurrency(cell.item / 100),
    },
    {key: 'item.status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
  ]

  const handleTabSelect = (eventKey: string | null) => {
    if (Object.values(tabs).includes(eventKey as Tab)) {
      setSelectedTab(eventKey as Tab)
    } else {
      console.error(`Unknown tab selected: ${eventKey}`)

      // Default to inventory tab
      setSelectedTab(tabs.inventory)
    }
  }
  const handleOrdersPagination = (pagination: Pagination) => {
    setNumOrders(pagination.total)
  }
  const handleInventoryPagination = (pagination: Pagination) => {
    setNumInventory(pagination.total)
  }

  return (
    <>
      <Row>
        <Col xs={'12'}>
          <Card className={'p-10 pb-0 product'}>
            <div className='d-block d-md-flex'>
              <div className='product-img'>
                <img src={itemDetails?.imageUrl} />
              </div>

              <div className='d-flex flex-column ps-0 ps-md-10 mt-5 mt-md-0'>
                <h1 className='title'>{itemDetails?.primaryTitle}</h1>

                <span className='subtitle d-block'>{itemDetails?.secondaryTitle}</span>
                <span className='subtitle d-block'>{itemDetails?.sku}</span>

                <div className='d-flex flex-column-reverse flex-grow-1 mt-5 mt-md-0'>
                  <div className='d-flex flex-row flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-150px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{numInventory}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Items</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-150px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{numOrders}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Sales</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-grow-1 text-md-end mt-5 mt-md-0'>
                <Link to={`create`}>
                  <Button variant={'primary'}>Create Items</Button>
                </Link>
              </div>
            </div>

            <div className='d-flex overflow-auto h-55px mt-10'>
              <Nav
                activeKey={selectedTab}
                onSelect={handleTabSelect}
                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'
              >
                <Nav.Item>
                  <Nav.Link eventKey={tabs.inventory}>Inventory</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Card>
        </Col>
      </Row>

      <Row className='mt-10'>
        <Col xs={'12'}>
          <Card className='p-10 pt-5'>
            <div style={{display: selectedTab === tabs.inventory ? 'block' : 'none'}}>
              <PaginatedTable
                key={'inventory'}
                className={'mt-5'}
                dataProperty={'items'}
                onPaginationUpdated={handleInventoryPagination}
                headers={inventoryHeaders}
                dataUrl={`/api/v1/item/view/product/${id}/all`}
                filterLocally={true}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const ProductPageWrapper = () => {
  const intl = useIntl()
  const tenantData = TenantService.getTenantFromLocalStorage()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCT_OVERVIEW'})}</PageTitle>
      <ProductPageContent />
      <Helmet>
        <title>
          {intl.formatMessage({id: 'MENU.PRODUCT_OVERVIEW'})}
          {tenantData?.title ? ` - ${tenantData.title}` : ''}
        </title>
      </Helmet>
    </>
  )
}

export const ProductPage = ProductPageWrapper
