import {useIntl} from 'react-intl'
import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {PaginatedTable, TableHeader} from "components";
import {Link} from "react-router-dom";
import TableUtilities from "components/dist/tables/table-utilities";
import {Card} from "react-bootstrap";
import {InventoryItem} from "../../inventory/services/inventory.service";
import {OrdersFilterDropdown} from "../components/OrdersFilterDropdown";
import {Helmet} from "react-helmet-async";
import TenantService from "../../tenant/services/TenantService";

const OrdersPageContent = () => {

    const [tableFilters, setTableFilters] = useState<Record<string, string>>({
        status: "all"
    });

    const titleAndSize = (row: any) => {
        return <div>
            <Link className={'text-white d-block'} to={`/inventory/items/${row.orderItem.item.id}`}>{row.orderItem.item.product.title}</Link>
            <span className={'mt-3'}>{row.orderItem.item.size}</span>
        </div>
    }

    const formatStatus = (status: boolean) => {
        return TableUtilities.statusBadge(status ? 'paid' : 'awaiting_payment');
    }

    const headers: TableHeader[] = [
        {key: 'image', label: '', format: (cell) => TableUtilities.tableImage(cell.row.orderItem.item.product.imageUrl, cell.row.orderItem.item.id)},
        {key: 'product.title', label: 'Item Title', format: (cell) => titleAndSize(cell.row)},
        {key: 'createdAt', label: 'Sale Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'orderItem.subtotal', label: 'Price', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'orderItem.payoutAmount', label: 'Payout Amount', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'transferSent', label: 'Status', format: (cell) => formatStatus(cell.item)},
    ]

    const onFilterChange = (filters: Record<string, string>) => {
        setTableFilters(filters);
    }

    const tableActions = () => (
        <>
            <div className='d-flex'>
                <OrdersFilterDropdown onFilterChange={onFilterChange}/>
            </div>
        </>
    )

    return (
        <>
            <Card>
                <Card.Body className='pt-10'>
                    <PaginatedTable
                        className=''
                        actions={tableActions()}
                        headers={headers}
                        method={'post'}
                        filters={tableFilters}
                        dataUrl={'/api/v1/order/consignor/search'}/>
                </Card.Body>
            </Card>
        </>
    )
}

const OrdersPage = () => {
    const intl = useIntl()

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ORDERS'})}</PageTitle>
            <OrdersPageContent/>
            <Helmet>
                <title>
                    {intl.formatMessage({id: 'MENU.ORDERS'})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}

export default OrdersPage