import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import {ItemCard} from "components";
import {Helmet} from "react-helmet-async";
import TenantService from "../../tenant/services/TenantService";

const events = [
    {
        boldText: "Sold",
        mutedText: "Item’s price was changed via dashboard",
        date: "April 17, 2023 at 1:02 PM",
        badgeText: "Bind"
    },
    {
        boldText: "Price changed to $160",
        mutedText: "Item’s price was changed via dashboard",
        date: "April 17, 2023 at 1:02 PM",
        badgeText: "Nolan Goldschmidt"
    },
    {
        boldText: "Price changed to $160",
        mutedText: "Item was authenticated and accepted into consignment",
        date: "April 17, 2023 at 1:02 PM",
        badgeText: "Alexander Rodriguez"
    },
    {
        boldText: "Created for consignment",
        mutedText: "An item was created and now awaiting drop-off",
        date: "March 30, 2023 at 1:02 PM",
        badgeText: "Nolan Goldschmidt"
    },
];

const item = {
    imageUrl: '/media/products/jordan.png',
    owner: {
        id: '123',
        fullName: 'Nolan Goldschmidt',
    },
    status: 'active',
    productCode: 'DZ5485-612',
    size: 8.5,
    price: 160,
    payout: 138,
    events: events,
}

const ViewOrderPageContent = () => {

    return (
        <>
            <Row>
                <Col xs={12} md={4} className='pe-5'>
                    <ItemCard
                        imageUrl={item.imageUrl}
                        subtitle={'Subtitle'}
                        productCode={'DZ5485-612'}
                        title={'Yeezy Boost 350 v2'}
                        location='Flagship Store'
                        status={'Sold'}
                    />
                </Col>

                <Col xs={12} md={8}>
                    <Card className={'mb-5'} title=''>
                        <div className='d-flex align-items-center justify-content-end me-7'>
                        </div>

                        <Card.Body>
                            <table className="table table-row-dashed align-middle" style={{tableLayout: 'fixed'}}>
                                <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th>Size</th>
                                    <th>List Price</th>
                                    <th>Payout</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{item.size}</td>
                                    <td>${item.price}</td>
                                    <td>${item.payout}</td>
                                </tr>
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <h2>Events</h2>
                            </Card.Title>

                            <ul className="timeline mb-5">
                                {item.events.map((item, index) => (
                                    <li key={index}>
                                        <span className={'fw-bold'}>{item.boldText}</span>
                                        <p className='text-muted'>{item.mutedText}</p>
                                        <small className="text-muted">{item.date}</small>
                                        <span className='badge badge-light ms-3'>{item.badgeText}</span>
                                    </li>
                                ))}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const ViewOrderPageWrapper = () => {
    const intl = useIntl()

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.VIEW_ORDER'})}</PageTitle>
            <ViewOrderPageContent/>
            <Helmet>
                <title>
                    {intl.formatMessage({id: 'MENU.VIEW_ORDER'})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}

export const ViewOrderPage = ViewOrderPageWrapper